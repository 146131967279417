import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isAdmin && _vm.enInvestorReportRedirect === "TRUE"
        ? _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c(
                VBtn,
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function () {
                      return _vm.handleInvestmentRepor()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("investment_report")))]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "mt-4",
          style:
            "background-color: " +
            (_vm.isAdmin ? "var(--secondary)" : "var(--primary)") +
            "; border-radius: 5px",
        },
        [
          _c(
            "p",
            {
              staticClass: "pa-2 mb-0",
              staticStyle: { color: "var(--white)", "font-weight": "normal" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("selected_for_user")) + " ")]
          ),
        ]
      ),
      _c(VSimpleTable, [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$tc("company", 1))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$tc("category", 1))),
            ]),
            _vm.loggedUser.type === _vm.UserTypeEnum.Banker ||
            _vm.loggedUser.type === _vm.UserTypeEnum.Partner
              ? _c("th", { staticClass: "text-center" }, [
                  _vm._v(" " + _vm._s(_vm.$tc("action_title")) + " "),
                ])
              : _vm._e(),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.User.opportunities, function (company, index) {
            return _c("tr", { key: index }, [
              _c("td", { staticClass: "text-center" }, [
                _vm._v(_vm._s(company.name)),
              ]),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(
                  " " +
                    _vm._s(company.category ? company.category.name : "-") +
                    " "
                ),
              ]),
              _vm.loggedUser.type === _vm.UserTypeEnum.Banker ||
              _vm.loggedUser.type === _vm.UserTypeEnum.Partner
                ? _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { small: "", color: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.viewBriefing(company)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("see_more")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "mt-4",
          style:
            "background-color: " +
            (_vm.isAdmin ? "var(--secondary)" : "var(--primary)") +
            "; border-radius: 5px",
        },
        [
          _c(
            "p",
            {
              staticClass: "pa-2 mb-0",
              staticStyle: { color: "var(--white)", "font-weight": "normal" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("requests")) + " ")]
          ),
        ]
      ),
      _c(VSimpleTable, [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$tc("company", 1))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$tc("category", 1))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("value"))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("status"))),
            ]),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.User.companyInterests, function (allocation, index) {
            return _c("tr", { key: index }, [
              _c("td", { staticClass: "text-center" }, [
                _vm._v(_vm._s(allocation.company.name)),
              ]),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      allocation.company.category
                        ? allocation.company.category.name
                        : "-"
                    ) +
                    " "
                ),
              ]),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.formatToCurrency(
                        allocation.currency,
                        allocation.value
                      )
                    ) +
                    " "
                ),
              ]),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("" + _vm.parseRequestStatus(allocation.status))
                    ) +
                    " "
                ),
              ]),
            ])
          }),
          0
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "mt-4",
          style:
            "background-color: " +
            (_vm.isAdmin ? "var(--secondary)" : "var(--primary)") +
            "; border-radius: 5px",
        },
        [
          _c(
            "p",
            {
              staticClass: "pa-2 mb-0",
              staticStyle: { color: "var(--white)", "font-weight": "normal" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("invested")) + " ")]
          ),
        ]
      ),
      _c(VSimpleTable, [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$tc("company", 1))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$tc("category", 1))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("value"))),
            ]),
            _vm.loggedUser.type === _vm.UserTypeEnum.Banker ||
            _vm.loggedUser.type === _vm.UserTypeEnum.Partner
              ? _c("th", { staticClass: "text-center" }, [
                  _vm._v(" " + _vm._s(_vm.$t("action_title")) + " "),
                ])
              : _vm._e(),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.User.investments, function (investment, index) {
            return _c("tr", { key: index }, [
              _c("td", { staticClass: "text-center" }, [
                _vm._v(_vm._s(investment.companyName)),
              ]),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      investment.categoryName ? investment.categoryName : "-"
                    ) +
                    " "
                ),
              ]),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.gs.format_to_currency(investment.totalInvested)
                    ) +
                    " "
                ),
              ]),
              _vm.loggedUser.type === _vm.UserTypeEnum.Banker ||
              _vm.loggedUser.type === _vm.UserTypeEnum.Partner
                ? _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { small: "", color: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.view_company(investment.companyId)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("see_more")) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }